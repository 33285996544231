import {ApolloProvider} from '@apollo/client'
import {CacheProvider, EmotionCache} from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles'
import * as Sentry from '@sentry/node'
import acceptLanguageParser from 'accept-language-parser'
import {AppContext, AppInitialProps, AppProps} from 'next/app'
import getConfig from 'next/config'
import Head from 'next/head'
import {SnackbarProvider} from 'notistack'

import {apolloClient} from 'api'
import Layout from 'components/layout'
import {PageViewTrackerWithUserId} from 'components/page-view-tracker'
import {SetSentryUser} from 'components/set-sentry-user'
import {TermsAndConditions} from 'contexts/terms-and-conditions'
import {UserProvider} from 'contexts/user'
import createEmotionCache from 'create-emotion-cache'
import {CustomI18nProvider} from 'i18n'
import * as settings from 'settings'
import {theme} from 'theme'

const {publicRuntimeConfig} = getConfig()

Sentry.init({
    dsn: publicRuntimeConfig.sentryPublicDSN,
    release: publicRuntimeConfig.version,
    environment: publicRuntimeConfig.environment,
    debug: publicRuntimeConfig.environment === 'development',
})

export type CustomAppProps = AppProps & {
    err?: Error
    emotionCache?: EmotionCache
    browserLanguage: string
}

// Example https://github.com/mui/material-ui/blob/62e0748c47a11a9bd49bc74fb6293ac710936b15/examples/material-next/pages/_document.js

const clientSideEmotionCache = createEmotionCache()

const CustomApp = ({
    Component,
    browserLanguage,
    pageProps,
    emotionCache = clientSideEmotionCache,
    err,
}: CustomAppProps) => {
    // Workaround for https://github.com/zeit/next.js/issues/8592
    const modifiedPageProps = {...pageProps, err}

    return (
        <CacheProvider value={emotionCache}>
            <Head>
                <title>{settings.SITE_NAME}</title>
                <meta
                    name='viewport'
                    content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'
                />
            </Head>
            <StyledEngineProvider injectFirst={true}>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider>
                        <ApolloProvider client={apolloClient}>
                            {/* <I18nProvider i18n={i18n}> */}
                            <UserProvider>
                                <CustomI18nProvider initialLanguage={browserLanguage}>
                                    <CssBaseline />
                                    <Layout>
                                        {/* <ExpiredQuotesProvider> */}
                                        <Component {...modifiedPageProps} />
                                        {/* </ExpiredQuotesProvider> */}
                                        <TermsAndConditions />
                                    </Layout>
                                    <PageViewTrackerWithUserId />
                                    <SetSentryUser />
                                </CustomI18nProvider>
                            </UserProvider>
                            {/* </I18nProvider> */}
                        </ApolloProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </CacheProvider>
    )
}

CustomApp.getInitialProps = async (appContext: AppContext): Promise<AppInitialProps> => {
    const {ctx} = appContext
    const browserLanguage = acceptLanguageParser.pick(
        ['es-ar', 'en-us'],
        ctx.req?.headers['accept-language'] || 'en-us',
    )
    return Promise.resolve({browserLanguage, pageProps: {}})
}
export default CustomApp
