import {t} from '@lingui/macro'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link,
    Stack,
    Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import {useSnackbar} from 'notistack'

import {useUser} from 'contexts/user'
import {Button} from 'core/components/button'
import {useLogout} from 'core/hooks/use-logout'
import {
    RuntimeSettingsFragment,
    useGetRuntimeSettingsQuery,
    UserDocument,
    useUpdateUserMutation,
} from 'generated/graphql'

export const TermsAndConditions = () => {
    const {user} = useUser()
    const {enqueueSnackbar} = useSnackbar()
    const {data} = useGetRuntimeSettingsQuery({
        skip: !user,
    })
    const runtimeSettings = data?.runtimeSettings as RuntimeSettingsFragment
    const termsAndConditions = runtimeSettings?.termsAndConditions
    const featureTermsAndConditions = runtimeSettings?.featureTermsAndConditions || false
    const termsAndConditionsUpdatedAt = runtimeSettings?.termsAndConditionsUpdatedAt

    const open = !user?.termsAndConditionsAcceptedAt

    const [updateUser] = useUpdateUserMutation({})
    const [logout] = useLogout()
    if (!user || !featureTermsAndConditions || user.termsAndConditionsAcceptedAt) return null
    const formattedDate = termsAndConditionsUpdatedAt ? dayjs(termsAndConditionsUpdatedAt).format('MMM D, YYYY') : null

    return (
        <>
            <Dialog open={open}>
                <DialogTitle>{t`Terms and conditions`}</DialogTitle>
                <DialogContent>
                    <Stack spacing={1}>
                        <DialogContentText>
                            {t`By using this service you are agreeing to the terms and conditions.`}
                        </DialogContentText>
                        {formattedDate && (
                            <Typography
                                variant='body2'
                                color='text.secondary'>
                                {t`Last updated: ${formattedDate}`}
                            </Typography>
                        )}
                        <Link
                            href={termsAndConditions ?? '#'}
                            target='_blank'>
                            {t`Terms and conditions`}
                        </Link>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        color='error'
                        onClick={async () => {
                            await updateUser({
                                variables: {
                                    input: {
                                        email: user.email,
                                        emailNotifications: user.emailNotifications,
                                        firstName: user.firstName,
                                        lastName: user.lastName,
                                        termsAndConditionsAcceptedAt: undefined,
                                    },
                                },
                            })
                            await logout()
                            enqueueSnackbar(
                                t`You have successfully declined the terms and conditions. You will be logged out.`,
                                {
                                    variant: 'info',
                                },
                            )
                        }}>{t`I decline`}</Button>
                    <Button
                        variant='contained'
                        onClick={async () => {
                            await updateUser({
                                variables: {
                                    input: {
                                        email: user.email,
                                        emailNotifications: user.emailNotifications,
                                        firstName: user.firstName,
                                        lastName: user.lastName,
                                        termsAndConditionsAcceptedAt: dayjs().format(),
                                    },
                                },
                                refetchQueries: [{query: UserDocument}],
                                awaitRefetchQueries: true,
                            })
                        }}>
                        {t`I agree`}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
