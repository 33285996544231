import {Button as MuiButton, ButtonProps as MuiButtonProps} from '@mui/material'
import React, {PropsWithChildren} from 'react'

export type ButtonProps = Pick<
    MuiButtonProps,
    | 'id'
    | 'onClick'
    | 'variant'
    | 'disabled'
    | 'sx'
    | 'fullWidth'
    | 'type'
    | 'startIcon'
    | 'endIcon'
    | 'color'
    | 'size'
    | 'children'
>

export const Button: React.FC<PropsWithChildren<ButtonProps>> = ({children, ...props}) => {
    return <MuiButton {...props}>{children}</MuiButton>
}
